<template>
  <div class="cancel">
    <top title="直付成功"></top>
    <!-- 核销成功内容 -->
    <div class="contain">
      <div class="pic">
        <img src="@/assets/img/succeed2@2x.png" alt="" />
      </div>
      <p>直付成功</p>
      <div class="divrice">￥{{ ciMoney }}</div>
      <div class="price">（账号余额：￥{{payMoney}}）</div>
    </div>
    <div class="button">
      <div class="btn1" @click="$router.push({path:'/home'})">返回首页</div>
      <div class="btn1 atv" @click="$router.push({path:'/record_cancel'})">查看直付记录</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ciMoney: "0.00",
      payMoney:'0.00',
    };
  },
  mounted(){
    let { ciMoney,payMoney } = this.$route.query;
    this.ciMoney = ciMoney;
    this.payMoney = payMoney
  },
};
</script>

<style lang="scss" scoped>
.cancel {
  width: 100%;
  .contain {
    width: 100%;
    .pic {
      width: vw(120);
      height: vw(120);
      margin: 0 auto;
      padding-top: vw(120);
      img {
        width: 100%;
      }
    }
    p {
      text-align: center;
        margin-top: vw(40);
      &:first-child {
        font-size: vw(30);
        color: #464040;
      }
    }
  }
  .divrice{
    text-align: center;
    margin-top: vw(22);
    font-weight: 700;
    font-size: vw(54);
    color: #019CF4;
  }
  .price{
    font-size: vw(28);
    color: #333333;
    text-align: center;
  }
  .btn1{
    background: #019CF4;
    color: #FFFFFF ;
    padding: vw(18) 0;
    text-align: center;
    border-radius: vw(50);
  }
  .atv{
    margin-top: vw(28);
    background: rgba(1,155,244,.1) ;
    color: #019CF4;

  }
  .button {
    font-size: vw(32);
    width: vw(620);
    margin: vw(100) auto;
    .record{
        color:$indexcolor;
        background-color: rgb(199, 234, 245);
        margin-top: vw(40);
    }
  }
}
</style>
